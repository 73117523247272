import '../styles/group-picker.scss';
import React, { useRef, useState, useEffect } from "react";
import { GuiCheckbox } from './global-ui/gui-checkbox';
import { SearchBox } from './search-box';
import { GuiButton } from './global-ui/gui-button';
import { Scroller } from './scroller';
import { useSelector } from 'react-redux';
import { pluralize, useUpdateState } from '../utilities/utilities';
import actions from '../actions/actions';
import { FilterDropdown } from '../components/filter-dropdown';
import { useOnFirstLoad } from '../utilities/hooks';



export function GroupPicker({ show, className, selected, onDone, onBack }) {
    useOnFirstLoad(actions.groups.initPage);
    let isMobile = useSelector(store => store.page.isMobile);
    let loadedItemsRef = useRef();
    let { locationOptions, gridColumns, groupSettingsForUser, groups } = useSelector(store => store.groups);
    let groupTypeOptions = useSelector(store => actions.groups.loadGroupTypes()) || [];
    const [filterGroups, setFilterGroups] = useState([]);
    let [state, updateState] = useUpdateState(() => {
      return {
          search: "",
          filters: [],
          filterChanged: false,
          gridReset: 0,
      }
  });
    useEffect(() => {
      async function fetchLocationOptions() {
          let result = await actions.groups.load(state.search || "", 1, 30, false);
          setFilterGroups(result.list);
      }

      fetchLocationOptions();
    }, []);

    if (show && !state.show && loadedItemsRef.current) {
        // Reset which items are selected
        loadedItemsRef.current.forEach(g => g.selected = selected.some(sel => sel.id === g.id));
        state.checked = [...selected];
    }
    state.show = show;

    const transformedFilterGroups = filterGroups.map(group => ({
      label: group.name,
      value: group.id || [] // Ensure options is an array
    }));

    let filterOptions = [

      { name: "High School", options: locationOptions || [] },
     { name: "Group Type", options: groupTypeOptions || [] },
    ];

    useEffect(() => {

    }, [state.filters, state.gridReset]);

    function onFilterChange(action, values) {
      if (action === "clear")
          updateState({ filters: [], gridReset: state.gridReset + 1 });
      else {
          let newFilters = state.filters.concat(values);
          updateState({ filters: newFilters, gridReset: state.gridReset + 1 });
      }
      }


    function setSearch(search) {
        updateState({ search: search, loading: true, gridReset: state.gridReset + 1 });
    }


  async function itemsNeeded(page, pageSize, skipTotal) {
    const sort = Object.keys(state.sort || {}).map(col => `${col} ${state.sort[col] ? "asc" : "desc"}`).join(",");
  
    const extractFilterValues = (filterName) => state.filters.filter(f => f.name === filterName).map(f => f.value);
    const locations = extractFilterValues("Location");
    const groupTypes = extractFilterValues("Group Type");
  
    const result = await actions.groups.getGroupGrid(state.search || "", locations, groupTypes, sort, page, pageSize, skipTotal);
  
    if (page === 1 && result) {
      const allUsersItem = { id: -1, name: "All Users", userCount: state.allUserCount };
      result.list.unshift(allUsersItem);
      actions.user.getUserCount().then(count => allUsersItem.userCount = count);
    }
  
    if (result && state.checked) {
      result.list.forEach(g => g.selected = state.checked.some(sel => sel.id === g.id));
    }
  
    updateState({ loading: false, totalCount: skipTotal ? state.totalCount : result.totalCount });
    return result;
  }
    

    function onItemClick(e) {
        if (e.target.tagName === "DIV") // Otherwise the checkbox's onChange will handle toggling it
        {
            let id = e.currentTarget.getAttribute("data-id");
            onItemChange(parseInt(id));
        }
    }

    function onItemChange(id) {
      let item = loadedItemsRef.current.find(i => i.id === id);
      item.selected = !item.selected;
  
      let checked = Array.isArray(state.checked) ? [...state.checked] : [];
  
      if (item.selected)
          checked = [...checked, item];
      else
          checked = checked.filter(g => g.id !== id);
  
      updateState({ checked: checked });
  }


    return (
        <div className={"group-picker " + (show ? " open " : " closed ") + (className || "")}>
            <div className="top-area">
                <div id="back" className="back" onClick={onBack}><i className="far fa-chevron-left"></i>{isMobile ? "" : " Back"}</div>
                <SearchBox onSearchChange={setSearch} searching={state.loading} />
                <FilterDropdown filters={filterOptions} onFilterChange={onFilterChange} />
                <GuiButton dataQa="done" onClick={e => onDone(state.checked)}>Done</GuiButton>
            </div>

            <Scroller
                reset={state.gridReset}
                itemHeight={47}
                onItemsNeeded={itemsNeeded}
                //onItemsNeeded={loadRows}
                loadedItemsRef={loadedItemsRef}
                itemTemplate={item =>
                    <div className={"item" + (item.selected ? " selected" : "") + (item.id === -1 ? " all-users" : "")} onClick={onItemClick} data-id={item.id} key={item.id}>
                        <GuiCheckbox label={item.name} name={item.id} value={item.selected} onChange={onItemChange} />
                        <div className="count">{item.userCount}</div>
                    </div>
                }
            />

            <div className="footer">
                {state.totalCount} {pluralize("Group", state.totalCount)}<span> | {state.checked?.length} Selected</span>
            </div>
        </div>
    );
}
